import React, { createContext, JSX, useState } from 'react';
import { ChildrenProps } from '@types/commonsTypes.ts';
import useCustomContext from '@hooks/useCustomContext.ts';

// TODO : Doc
type HeaderContextProps = {
	leftHeader: React.ReactNode;
	setLeftHeader: (value: React.ReactNode) => void;
	centerHeader: React.ReactNode;
	setCenterHeader: (value: React.ReactNode) => void;
	rightHeader: React.ReactNode;
	setRightHeader: (value: React.ReactNode) => void;
};

// TODO : Doc
const HeaderContext: React.Context<HeaderContextProps | null> =
	createContext<HeaderContextProps | null>(null);

// TODO : Doc
function useHeaderContext(): HeaderContextProps {
	return useCustomContext(HeaderContext);
}

// TODO : Doc
function HeaderProvider({ children }: ChildrenProps): JSX.Element {
	const [leftHeader, setLeftHeader] = useState<React.ReactNode>();
	const [centerHeader, setCenterHeader] = useState<React.ReactNode>();
	const [rightHeader, setRightHeader] = useState<React.ReactNode>();

	return (
		<HeaderContext.Provider
			value={{
				leftHeader,
				setLeftHeader,
				centerHeader,
				setCenterHeader,
				rightHeader,
				setRightHeader,
			}}
		>
			{children}
		</HeaderContext.Provider>
	);
}

export type { HeaderContextProps };
export { useHeaderContext };
export default HeaderProvider;
