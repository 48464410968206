import { JSX } from 'react';
import Page from '@components/pages/page';
import Loader from '@components/loaders/loader';
import '@styles/pages/loader/pageLoader.scss';

// TODO : Doc
function PageLoader(): JSX.Element {
	return (
		<Page className={'page-loader'}>
			<Loader />
		</Page>
	);
}

export default PageLoader;
