import {
	ActionDispatch,
	createContext,
	JSX,
	useEffect,
	useReducer,
} from 'react';
import { disconnectRequest } from '@services/api/callsAPI/AuthAPI';
import { authReducer } from '@reducers/authReducer.ts';
import {
	AuthAction,
	AuthActionType,
	AuthState,
} from '@reducers/actionTypes.ts';
import {
	getFromDB,
	removeFromDB,
	setToDB,
} from '@services/storages/localDB.ts';
import useCustomContext from '@hooks/useCustomContext.ts';
import { ChildrenProps } from '@types/commonsTypes.ts';

// TODO : Doc
type AuthContextProps = {
	state: AuthState;
	dispatch: ActionDispatch<[action: AuthAction]>;
	disconnect: () => void;
};

// TODO : Doc
const AuthContext: React.Context<AuthContextProps | null> =
	createContext<AuthContextProps | null>(null);

// TODO : Doc
function useAuthContext(): AuthContextProps {
	return useCustomContext(AuthContext);
}

// TODO : Doc
function AuthProvider({ children }: ChildrenProps): JSX.Element {
	const STORE_NAMES: string = 'auth';
	const [state, dispatch] = useReducer(authReducer, {
		user: null,
		asPaid: null,
		roles: null,
		token: null,
		isLoggedIn: false,
	});

	const loadLocal = async (): Promise<AuthType | undefined> => {
		const storedUser: UserType | undefined = await getFromDB(
			STORE_NAMES,
			'user',
		);
		const storedAsPaid: boolean | undefined = await getFromDB(
			STORE_NAMES,
			'asPaid',
		);
		const storedRoles: number[] | undefined = await getFromDB(
			STORE_NAMES,
			'roles',
		);
		const storedToken: TokenType | undefined = await getFromDB(
			STORE_NAMES,
			'token',
		);

		if (
			storedUser !== undefined &&
			storedAsPaid !== undefined &&
			storedRoles !== undefined &&
			storedToken !== undefined
		) {
			return {
				user: storedUser,
				asPaid: storedAsPaid,
				roles: storedRoles,
				token: storedToken,
			};
		}

		return undefined;
	};

	const saveLocal = async (auth: AuthType): Promise<void> => {
		await setToDB(STORE_NAMES, auth.user, 'user');
		await setToDB(STORE_NAMES, auth.asPaid, 'asPaid');
		await setToDB(STORE_NAMES, auth.roles, 'roles');
		await setToDB(STORE_NAMES, auth.token, 'token');
	};

	const removeLocal = async (): Promise<void> => {
		await removeFromDB(STORE_NAMES, 'user');
		await removeFromDB(STORE_NAMES, 'asPaid');
		await removeFromDB(STORE_NAMES, 'roles');
		await removeFromDB(STORE_NAMES, 'token');
	};

	const disconnect = async () => {
		// Call Backend
		disconnectRequest().catch((err: Error) =>
			console.error('Disconnect error : ', err),
		);

		dispatch({
			type: AuthActionType.LOGOUT,
		});

		// TODO: check if data is remove from idb after disconnection whit error
		// TODO: check if useEffect for automatically update IDB is a great idea
		await removeLocal();
	};

	// useEffect(() => {
	// 	// TODO: refactor services/refreshToken.ts and services/api/interceptors.ts for direct use authProvider and not call storages.
	//
	// 	const checkToken = async () => {
	// 		const token = await loadTokenLocal();
	// 		if (!token) {
	// 			disconnect();
	// 			clearInterval(interval);
	// 		} // Déconnexion automatique si plus de token
	// 	};
	//
	// 	const interval = setInterval(checkToken, 5 * 60 * 1000); // Vérifie toutes les 5 minutes
	// 	return () => clearInterval(interval);
	// }, []);

	// To load the status at application startup
	useEffect(() => {
		const load = async () => {
			const authLocal: AuthType | undefined = await loadLocal();
			if (authLocal) {
				dispatch({
					type: AuthActionType.SET_STATE,
					payload: {
						user: authLocal.user,
						asPaid: authLocal.asPaid,
						roles: authLocal.roles,
						token: authLocal.token,
					},
				});
				dispatch({
					type: AuthActionType.LOGIN,
				});
			}
		};

		load();
	}, []);

	// To automatically update IDB according to state
	useEffect(() => {
		// TODO : Check this
		if (
			state.isLoggedIn &&
			state.user !== null &&
			state.asPaid !== null &&
			state.roles !== null &&
			state.token !== null
		) {
			saveLocal({
				user: state.user,
				asPaid: state.asPaid,
				roles: state.roles,
				token: state.token,
			});
		} else {
			console.debug('¯\\_༼ ಥ ‿ ಥ ༽_/¯', state);
		}
	}, [state]);

	return (
		<AuthContext.Provider
			value={{
				state,
				dispatch,
				disconnect,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export type { AuthContextProps };
export { useAuthContext };
export default AuthProvider;
