import { api } from '@services/api/api';
import {
	getFromDB,
	removeFromDB,
	setToDB,
} from '@services/storages/localDB.ts';

// TODO: Doc
async function refreshToken() {
	try {
		const user: UserType | undefined = await getFromDB('auth', 'user');
		const { data } = await api.post('/api/Auth/RefreshToken', user?.id, {
			withCredentials: true,
		});
		const newToken = {
			accessToken: data.accessToken,
		};
		await setToDB('auth', newToken, 'token');
		return newToken;
	} catch (error) {
		console.error('Refresh fail : ', error);
		await removeFromDB('auth', 'token');
		return null;
	}
}

export default refreshToken;
