import { IDBPDatabase, openDB } from 'idb';

const DB_NAME = 'authDB';
const DB_VERSION = 1;
const STORE_NAMES = ['auth'];

let dbInstance: IDBPDatabase | null = null;

// TODO: Doc
async function initDB() {
	if (dbInstance) return dbInstance;

	dbInstance = await openDB(DB_NAME, DB_VERSION, {
		upgrade(db) {
			STORE_NAMES.forEach((store) => {
				if (!db.objectStoreNames.contains(store)) {
					db.createObjectStore(store);
				}
			});
		},
	});

	return dbInstance;
}

// TODO: Doc
async function getFromDB<D>(
	storeName: string,
	itemName: string,
): Promise<D | undefined> {
	try {
		const db = await initDB();
		return await db.get(storeName, itemName);
	} catch (error) {
		console.error('Error retrieving data from DB:', error);
		return undefined;
	}
}

// TODO: Doc
async function setToDB(
	storeName: string,
	data: any,
	itemName: string,
): Promise<void> {
	const db = await initDB();
	await db.put(storeName, data, itemName);
}

// TODO: Doc
async function removeFromDB(
	storeName: string,
	itemName: string,
): Promise<void> {
	const db = await initDB();
	await db.delete(storeName, itemName);
}

export { getFromDB, setToDB, removeFromDB };
export default initDB;
