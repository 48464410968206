import { JSX } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ThemeProvider from '@app/providers/themeProvider';
import AuthProvider from '@app/providers/authProvider';
import TranslateProvider from '@app/providers/translateProvider';
import HeaderProvider from '@app/providers/headerProvider';
import NotificationsProvider from '@app/providers/notificationsProvider';
import AlertProvider from '@app/providers/alertsProvider';
import { ChildrenProps } from '@types/commonsTypes';

// TODO : Doc
function AppProvider({ children }: ChildrenProps): JSX.Element {
	const clientId: string = import.meta.env.VITE_APP_GOOGLE_OAUTH_CLIENT_ID!;

	return (
		<ThemeProvider>
			<AlertProvider>
				<NotificationsProvider>
					<AuthProvider>
						<TranslateProvider>
							<HeaderProvider>
								<GoogleOAuthProvider clientId={clientId}>
									<>{children}</>
								</GoogleOAuthProvider>
							</HeaderProvider>
						</TranslateProvider>
					</AuthProvider>
				</NotificationsProvider>
			</AlertProvider>
		</ThemeProvider>
	);
}

export default AppProvider;
