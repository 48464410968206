import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const isDebug: boolean = false; // TODO : debug i18n

const fallbackLng: string = 'en';
const supportedLngs: string[] = ['en', 'fr'];
const backendOptions = {
	loadPath: '/locales/{{lng}}/{{ns}}.json',
	//crossDomain: true,
};

i18n
	// load translation using http
	.use(Backend)
	// detect user language
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	.init({
		//load: 'languageOnly', // ignores variants such as “fr-FR”
		debug: isDebug,
		backend: backendOptions,
		fallbackLng: fallbackLng,
		supportedLngs: supportedLngs,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		react: {
			useSuspense: true,
		},
	});

export default i18n;
