// TODO : translate doc
/**
 * Concatène des classes CSS en une seule chaîne.
 *
 * @param {...string[]} classes - Une liste de classes CSS
 * @returns {string} Une chaîne de classes concaténées
 *
 * @example
 * // Simple concaténation
 * const result = concatClass('class1', 'class2');
 * console.log(result); // 'class1 class2'
 *
 * @example
 * // Ignorer les classes falsy
 * const result = concatClass('class1', undefined, 'class2', false);
 * console.log(result); // 'class1 class2'
 */
function concatClass(...classes: Array<string | undefined>): string {
	return classes.join(' ');
}

export default concatClass;
