import { JSX } from 'react';
import { Navigate } from 'react-router';

// TODO : Doc
type AppNavigateProps = {
	to: string;
	replace?: boolean;
};

// TODO: Doc
function AppNavigate({ to, replace }: AppNavigateProps): JSX.Element {
	return (
		<Navigate
			to={to}
			state={{ fromInternal: true }}
			replace={replace ?? false}
		/>
	);
}

export type { AppNavigateProps };
export default AppNavigate;
