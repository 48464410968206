import { AxiosInstance } from 'axios';
import refreshToken from '@services/refreshToken';
import { getFromDB } from '@services/storages/localDB.ts';

// TODO : Doc
function applyInterceptors(api: AxiosInstance): void {
	// Add token in all requests
	api.interceptors.request.use(
		async (config) => {
			const token: TokenType | undefined = await getFromDB(
				'auth',
				'token',
			);
			if (token)
				config.headers.Authorization = `Bearer ${token.accessToken}`;
			return config;
		},
		(error) => Promise.reject(error),
	);

	// Manage errors
	api.interceptors.response.use(
		(response) => response,
		async (error) => {
			if (error.response?.status === 401) {
				const newToken = await refreshToken();
				if (newToken) {
					error.config.headers.Authorization = `Bearer ${newToken}`;
					return api.request(error.config);
				}
			}
			return Promise.reject(error);
		},
	);
}

export { applyInterceptors };
