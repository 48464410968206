import { JSX, lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router';
import PageLoader from '@pages/loader/pageLoader.tsx';
import { ProtectedRouteType } from '@enums';
import AppNavigate from '@components/navigates/appNavigate.tsx';
import AppOutlet from '@components/outlets/appOutlet.tsx';

//region Lazy import
// Protected routes
const ProtectedRoute = lazy(
	() => import('@components/protectedRoute/protectedRoute'),
);

// Auth pages
const AuthLayout = lazy(() => import('@pages/auth/authLayout'));
const Login = lazy(() => import('@pages/auth/login/login'));
const Register = lazy(() => import('@pages/auth/register/register'));
const ForgotPassword = lazy(
	() => import('@pages/auth/forgotPassword/forgotPassword'),
);

// App Providers
const ItineraryProvider = lazy(
	() => import('@app/providers/itineraryProvider.tsx'),
);
const PlacesProvider = lazy(() => import('@app/providers/placesProvider'));
const MapProvider = lazy(() => import('@app/providers/mapProvider'));

// App Layouts & Pages
const AppLayout = lazy(() => import('@pages/app/appLayout'));
const NavLayout = lazy(() => import('@pages/app/navLayout'));
const Home = lazy(() => import('@pages/app/itinerary/home'));
const Details = lazy(() => import('@pages/app/itinerary/details'));
const Setup = lazy(() => import('@pages/app/itinerary/setup'));
const Method = lazy(() => import('@pages/app/itinerary/chooseMethod'));
const Review = lazy(() => import('@pages/app/itinerary/review'));
const NavigationMap = lazy(() => import('@pages/app/map/navigationMap'));
const Notification = lazy(
	() => import('@pages/app/notification/notification.tsx'),
);
const Profile = lazy(() => import('@pages/app/profile/profile'));

// Payment
const PaymentLayout = lazy(() => import('@pages/payment/paymentLayout'));
const Payment = lazy(() => import('@pages/payment/payment'));
const PaymentSuccess = lazy(() => import('@pages/payment/paymentSuccess'));
const PaymentCancel = lazy(() => import('@pages/payment/paymentCancel'));

// Misc Pages
const Terms = lazy(() => import('@pages/rgpd/terms'));
const Privacy = lazy(() => import('@pages/rgpd/privacy'));
const Welcome = lazy(() => import('@pages/welcome/welcome'));
const AlertContainer = lazy(() => import('@components/alerts/alert.tsx'));
const NotFound = lazy(() => import('@pages/notFound/notFound'));
//endregion

// TODO: Doc
function AppRouter(): JSX.Element {
	const publicRoutes = [
		{ index: true, element: <Welcome /> },
		{ path: 'terms', element: <Terms /> },
		{ path: 'privacy', element: <Privacy /> },
		{ path: '*', element: <NotFound /> },
	];

	const authRoutes = [
		{
			path: 'auth',
			element: <AuthLayout />,
			children: [
				{ index: true, element: <AppNavigate to="login" replace /> },
				{ path: 'login', element: <Login /> },
				{ path: 'register', element: <Register /> },
				{ path: 'forgot-password', element: <ForgotPassword /> },
			],
		},
	];

	const unpaidUserRoutes = [
		{
			path: 'payment',
			element: (
				<ProtectedRoute allowedType={ProtectedRouteType.UNPAID_USER}>
					<PaymentLayout />
				</ProtectedRoute>
			),
			children: [
				{ index: true, element: <Payment /> },
				{ path: 'success', element: <PaymentSuccess /> },
				{ path: 'cancel', element: <PaymentCancel /> },
			],
		},
	];

	const userRoutes = [
		{
			path: 'app',
			element: (
				<ProtectedRoute allowedType={ProtectedRouteType.USER}>
					<ItineraryProvider>
						<PlacesProvider>
							<MapProvider>
								<AppLayout />
							</MapProvider>
						</PlacesProvider>
					</ItineraryProvider>
				</ProtectedRoute>
			),
			children: [
				{
					index: true,
					element: <AppNavigate to="home" replace />,
				},
				{
					element: <NavLayout />,
					children: [
						{ path: 'home', element: <Home /> },
						{ path: 'map', element: <NavigationMap /> },
					],
				},
				{ path: 'details/:id', element: <Details /> },
				{ path: 'setup', element: <Setup /> },
				{ path: 'method', element: <Method /> },
				{ path: 'review', element: <Review /> },
				{ path: 'notification', element: <Notification /> },
				{ path: 'profile', element: <Profile /> },
			],
		},
	];

	const adminRoutes = [{}];

	const router = createBrowserRouter([
		{
			element: (
				<>
					<AlertContainer />
					<AppOutlet />
				</>
			),
			children: [
				...publicRoutes,
				{
					element: (
						<ProtectedRoute
							allowedType={ProtectedRouteType.NOT_CONNECTED}
						/>
					),
					children: authRoutes,
				},
				{
					element: (
						<ProtectedRoute
							allowedType={ProtectedRouteType.CONNECTED}
						/>
					),
					children: [
						...unpaidUserRoutes,
						...userRoutes,
						...adminRoutes,
					],
				},
			],
		},
	]);

	return (
		<Suspense fallback={<PageLoader />}>
			<RouterProvider router={router} />
		</Suspense>
	);
}

export default AppRouter;
