import axios, { AxiosInstance } from 'axios';
import { applyInterceptors } from '@services/api/interceptors';

// TODO : Doc
const api: AxiosInstance = axios.create({
	baseURL: import.meta.env.VITE_APP_BACKEND_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

applyInterceptors(api);

export { api };
