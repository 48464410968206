// TODO: Doc
type ActionType<D> = {
	type: D;
	payload?: any;
	error?: any;
};

//region Auth Reducer
// TODO: Doc
type AuthState = {
	user: UserType | null;
	asPaid: boolean | null;
	roles: number[] | null;
	token: TokenType | null;
	isLoggedIn: boolean;
};

/**
 * Enum for action auth reducer
 */
enum AuthActionType {
	LOGIN = 'LOGIN',
	LOGOUT = 'LOGOUT',
	SET_STATE = 'SET_STATE',
	SET_ASPAID = 'SET_ASPAID',
}

// TODO : Doc
type AuthAction = ActionType<AuthActionType>;

export { AuthActionType };
export type { AuthState, AuthAction };
//endregion

//region Places Reducer
// TODO: Doc
type PlacesState = {
	categories: PlaceCategoryType[];
	loadingCategories: boolean;
	errorCategories: string | null;
	places: PlaceType[];
	loadingPlaces: boolean;
	errorPlaces: string | null;
	nextPageToken: string | null;
};

/**
 * Enum for action place reducer
 */
enum PlacesActionType {
	GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST',
	GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS',
	GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR',

	GET_PLACES_REQUEST = 'GET_PLACES_REQUEST',
	GET_PLACES_SUCCESS = 'GET_PLACES_SUCCESS',
	GET_PLACES_ERROR = 'GET_PLACES_ERROR',

	GET_PICTURE_PLACE_REQUEST = 'GET_PICTURE_PLACE_REQUEST',
	GET_PICTURE_PLACE_SUCCESS = 'GET_PICTURE_PLACE_SUCCESS',
	GET_PICTURE_PLACE_ERROR = 'GET_PICTURE_PLACE_ERROR',
}

// TODO: Doc
type PlacesAction = ActionType<PlacesActionType>;

export { PlacesActionType };
export type { PlacesState, PlacesAction };
//endregion
