import React, { useContext } from 'react';

/**
 * Generic hook to safely retrieve a React context.
 *
 * This hook ensures that a given `Context` is properly used within its `Provider`.
 * If the context is not available, an error is thrown, preventing accidental
 * access to an undefined context.
 *
 * @template T - The type of data stored in the context.
 * @param {Context<T | null>} context - The React context to retrieve.
 * @returns {T} - The context value, guaranteed to be non-null.
 *
 * @throws {Error} - If the context is `null`, an error is thrown to ensure
 *                   that the component is wrapped inside the correct `Provider`.
 *
 * @example
 * ```tsx
 * const itinerary = useCustomContext(ItineraryContext);
 * itinerary.setDestination(place, data.start_date, data.nbr_days);
 * ```
 */
function useCustomContext<T>(context: React.Context<T | null>): T {
	const ctx = useContext(context);

	if (ctx === null) {
		throw new Error(
			`useCustomContext must be used within a ${typeof context}`,
		);
	}

	return ctx; // Ensures TypeScript that ctx is properly defined
}

export default useCustomContext;
