/**
 * Stores a value in localStorage under the given key.
 *
 * @param {string} key - The key to store the value under.
 * @param {any} value - The value to store. Objects and arrays will be serialized to JSON.
 */
function setLocalStorage(key: string, value: any): void {
	try {
		const serializedValue = JSON.stringify(value);
		localStorage.setItem(key, serializedValue);
	} catch (error) {
		console.error(`Error saving "${key}" to localStorage:`, error);
	}
}

/**
 * Retrieves a value from localStorage by key.
 *
 * @param {string} key - The key to retrieve the value from.
 * @returns {any | null} - The stored value, parsed from JSON if possible, or null if the key does not exist.
 */
function getLocalStorage<D>(key: string): D | null {
	const storedValue = localStorage.getItem(key);
	return storedValue ? JSON.parse(storedValue) : storedValue;
}

/**
 * Removes a value from localStorage by key.
 *
 * @param {string} key - The key to remove from localStorage.
 */
function removeLocalStorage(key: string): void {
	localStorage.removeItem(key);
}

export { setLocalStorage, getLocalStorage, removeLocalStorage };
