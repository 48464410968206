//region Component Enums
/**
 * Enum for available button
 */
enum ButtonType {
	SUBMIT = 'submit',
	RESET = 'reset',
	BUTTON = 'button',
}

/**
 * Enum for available input
 */
enum InputType {
	// COLOR = 'color',
	DATE = 'date',
	// DATETIME_COCAL = 'datetime-local',
	EMAIL = 'email',
	// FILE = 'file',
	// HIDDEN = 'hidden',
	// IMAGE = 'image',
	// MONTH = 'month',
	NUMBER = 'number',
	PASSWORD = 'password',
	// RADIO = 'radio',
	// RANGE = 'range',
	// SEARCH = 'search',
	// TEL = 'tel',
	TEXT = 'text',
	// TIME = 'time',
	// URL = 'url',
	// WEEK = 'week',
}

/**
 * Enum for protect routes
 */
enum ProtectedRouteType {
	NOT_CONNECTED = 'notConnected',
	CONNECTED = 'connected',
	UNPAID_USER = 'UNPAID_USER',
	USER = 'user',
	ADMIN = 'admin',
}

export { ButtonType, InputType, ProtectedRouteType };
//endregion

//region Call API Enums
// TODO : Doc
enum ErrorCodeType {
	//region User Controller
	ERR_USER_EMAIL = 'ERR_USER_EMAIL',
	ERR_USER_EMAIL_EXIST = 'ERR_USER_EMAIL_EXIST',
	ERR_USER_FIRSTNAME = 'ERR_USER_FIRSTNAME',
	ERR_USER_LASTNAME = 'ERR_USER_LASTNAME',
	ERR_USER_PASSWORD = 'ERR_USER_PASSWORD',
	ERR_USER_UPDATE_PASSWORD_ID = 'ERR_USER_UPDATE_PASSWORD_ID',
	ERR_USER_ADD = 'ERR_USER_ADD',
	ERR_USER_GET = 'ERR_USER_GET',
	ERR_USER_GET_BY_EMAIL = 'ERR_USER_GET_BY_EMAIL',
	// User Role
	ERR_USER_ROLE_ID = 'ERR_USER_ROLE_ID',
	ERR_USER_ROLE_ADD = 'ERR_USER_ROLE_ADD',
	ERR_USER_ROLE_GET = 'ERR_USER_ROLE_GET',
	//endregion

	//region Auth Controller
	// Login
	ERR_AUTH_LOGIN_GET = 'ERR_AUTH_LOGIN_GET',
	ERR_AUTH_LOGIN_EMAIL = 'ERR_AUTH_LOGIN_EMAIL',
	ERR_AUTH_LOGIN_PASSWORD = 'ERR_AUTH_LOGIN_PASSWORD',
	// Login By Google
	ERR_AUTH_GOOGLE_CODE = 'ERR_AUTH_GOOGLE_CODE',
	ERR_AUTH_GOOGLE_PAYLOAD = 'ERR_AUTH_GOOGLE_PAYLOAD',
	// Refresh Token
	ERR_AUTH_REFRESH_TOKEN_DATA = 'ERR_AUTH_REFRESH_TOKEN_DATA',
	ERR_AUTH_REFRESH_TOKEN_EXPIRE = 'ERR_AUTH_REFRESH_TOKEN_EXPIRE',
	ERR_AUTH_USER_ID = 'ERR_AUTH_USER_ID',
	//endregion

	//region JwtHandler
	ERR_JWT_COOKIE_REFRESH_TOKEN = 'ERR_JWT_COOKIE_REFRESH_TOKEN',
	ERR_JWT_TOKEN_ID = 'ERR_JWT_TOKEN_ID',
	//endregion

	//region Place Controller
	ERR_PLACE_PICTURE_PLACE_EMPTY_OR_NULL = 'ERR_PLACE_PICTURE_PLACE_EMPTY_OR_NULL',
	//endregion

	//region Payment Controller
	ERR_PAYMENT_ID = 'ERR_PAYMENT_ID',
	ERR_PAYMENT_SESSION = 'ERR_PAYMENT_SESSION',
	ERR_PAYMENT_USER = 'ERR_PAYMENT_USER',
	ERR_PAYMENT_EMAIL = 'ERR_PAYMENT_EMAIL',
	ERR_PAYMENT_USER_EMAIL = 'ERR_PAYMENT_USER_EMAIL',
	ERR_PAYMENT_USER_ID = 'ERR_PAYMENT_USER_ID',
	ERR_PAYMENT_ADD = 'ERR_PAYMENT_ADD',
	ERR_PAYMENT_GET = 'ERR_PAYMENT_GET',
	//endregion

	//region Role Controller
	ERR_ROLE_ID = 'ERR_ROLE_ID',
	ERR_ROLE_ADD = 'ERR_ROLE_ADD',
	ERR_ROLE_GET = 'ERR_ROLE_GET',
	//endregion
}

// TODO : Doc
enum TravelModeType {
	car,
	truck,
	bicycle,
	pedestrian,
}

// TODO : Doc
enum RoleType {
	ADMIN,
	USER,
}

export { ErrorCodeType, TravelModeType, RoleType };
//endregion
