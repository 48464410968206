import { JSX } from 'react';
import '@styles/components/loaders/loader.scss';

// TODO : Doc
function Loader(): JSX.Element {
	// TODO : Translate
	return <div className={'component-loader'}>Loading...</div>;
}

export default Loader;
