import React, { createContext, JSX, useState } from 'react';
import useCustomContext from '@hooks/useCustomContext.ts';
import { ChildrenProps } from '@types/commonsTypes.ts';

// TODO: Doc
type AlertContextProps = {
	alerts: AlertProps[];
	addAlert: (alert: AlertProps) => void;
	removeAlert: (id: number) => void;
};

// TODO: Doc
// TODO: Move ?
enum AlertType {
	SUCCESS = 'success',
	ERROR = 'error',
	INFO = 'info',
	WARNING = 'warning',
}
type AlertProps = {
	id: number; // Time
	message: string;
	type: AlertType;
};

// TODO: Doc
const AlertContext: React.Context<AlertContextProps | null> =
	createContext<AlertContextProps | null>(null);

// TODO: Doc
function useAlertContext(): AlertContextProps {
	return useCustomContext(AlertContext);
}

// TODO: Doc
function AlertProvider({ children }: ChildrenProps): JSX.Element {
	const [alerts, setAlerts] = useState<AlertProps[]>([]);

	const addAlert = (alert: AlertProps) => {
		// TODO: add verification
		setAlerts((prev) => [...prev, alert]);
	};

	const removeAlert = (id: number) => {
		setAlerts((prev) => prev.filter((alert) => alert.id !== id));
	};

	return (
		<AlertContext.Provider
			value={{
				alerts,
				addAlert,
				removeAlert,
			}}
		>
			{children}
		</AlertContext.Provider>
	);
}

export type { AlertContextProps, AlertProps };
export { AlertType, useAlertContext };
export default AlertProvider;
