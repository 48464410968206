import { JSX } from 'react';
import AppProvider from '@app/providers/appProvider';
import AppRouter from '@app/routes/appRouter';

// TODO : Doc
function App(): JSX.Element {
	return (
		<AppProvider>
			<AppRouter />
		</AppProvider>
	);
}

export default App;
