import { JSX } from 'react';
import concatClass from '@utils/concatClass';
import { ChildrenProps, CommonProps } from '@types/commonsTypes.ts';
import '@styles/components/pages/page.scss';

// TODO : Doc
type PageProps = CommonProps & ChildrenProps;

// TODO : Doc
function Page({ id, className, children }: PageProps): JSX.Element {
	return (
		<div id={id} className={concatClass('component-page', className)}>
			{children}
		</div>
	);
}

export type { PageProps };
export default Page;
