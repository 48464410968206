import React, { createContext, JSX, useEffect, useState } from 'react';
import useCustomContext from '@hooks/useCustomContext.ts';
import { ChildrenProps } from '@types/commonsTypes.ts';
import {
	getLocalStorage,
	setLocalStorage,
} from '@services/storages/localStorage.ts';

// TODO: Doc
type NotificationsContextProps = {
	notification: boolean;
	toggleNotification: () => void;
};

// TODO: Doc
const NotificationsContext: React.Context<NotificationsContextProps | null> =
	createContext<NotificationsContextProps | null>(null);

// TODO: Doc
function useNotificationContext(): NotificationsContextProps {
	return useCustomContext(NotificationsContext);
}

// TODO: Doc
function NotificationsProvider({ children }: ChildrenProps): JSX.Element {
	const [notification, setNotification] = useState<boolean>(() => {
		const value = getLocalStorage<boolean>('notifications');
		return value !== null ? value : true;
	});

	const toggleNotification = () => setNotification((prev) => !prev);

	// Update local storage
	useEffect(() => {
		setLocalStorage('notifications', notification);
	}, [notification]);

	return (
		<NotificationsContext.Provider
			value={{
				notification,
				toggleNotification,
			}}
		>
			{children}
		</NotificationsContext.Provider>
	);
}

export type { NotificationsContextProps };
export { useNotificationContext };
export default NotificationsProvider;
