import React, { createContext, JSX, useEffect, useState } from 'react';
import i18n from '@services/i18n';
import useCustomContext from '@hooks/useCustomContext.ts';
import { ChildrenProps } from '@types/commonsTypes.ts';

// TODO : Doc
type TranslateContextProps = {
	currentLang: string;
	langs: LangType[];
	handleChangeLanguage: (value: string) => void;
};

// TODO : Doc
const TranslateContext: React.Context<TranslateContextProps | null> =
	createContext<TranslateContextProps | null>(null);

// TODO : Doc
function useTranslateContext(): TranslateContextProps {
	return useCustomContext(TranslateContext);
}

// TODO : Doc
function TranslateProvider({ children }: ChildrenProps): JSX.Element {
	// TODO : Caution, check language compatibility with APIs
	const [currentLang, setCurrentLang] = useState<string>(i18n.language);
	const langs: LangType[] = [
		{ value: 'en', label: 'English' },
		{ value: 'fr', label: 'Français' },
	];

	const handleChangeLanguage = (value: string): void => {
		i18n.changeLanguage(value);
	};

	useEffect(() => {
		i18n.on('languageChanged', () => {
			setCurrentLang(i18n.language);
		});

		return (): void => {
			i18n.off('languageChanged', () => setCurrentLang(i18n.language));
		};
	}, []);

	return (
		<TranslateContext.Provider
			value={{
				currentLang,
				langs,
				handleChangeLanguage,
			}}
		>
			{children}
		</TranslateContext.Provider>
	);
}

export type { TranslateContextProps };
export { useTranslateContext };
export default TranslateProvider;
