import {
	AuthAction,
	AuthActionType,
	AuthState,
} from '@reducers/actionTypes.ts';

// TODO : Doc
function authReducer(state: AuthState, action: AuthAction): AuthState {
	switch (action.type) {
		case AuthActionType.LOGIN:
			return { ...state, isLoggedIn: true };
		case AuthActionType.LOGOUT:
			return {
				...state,
				isLoggedIn: false,
				user: null,
				asPaid: null,
				roles: null,
				token: null,
			};
		case AuthActionType.SET_STATE:
			return {
				...state,
				user: action.payload.user,
				asPaid: action.payload.asPaid,
				roles: action.payload.roles,
				token: action.payload.token,
			};
		case AuthActionType.SET_ASPAID:
			return {
				...state,
				asPaid: action.payload.asPaid,
			};
		default:
			return state;
	}
}

export { authReducer };
