import { AxiosResponse } from 'axios';
import { CodeResponse } from '@react-oauth/google';
import { api } from '@services/api/api.ts';

// TODO : Doc
function loginRequest(
	data: FormFieldsLogin,
	signal: AbortSignal,
): Promise<AxiosResponse<AuthType>> {
	return api.post('/api/Auth/Login', data, { signal, withCredentials: true });
}

// TODO : Doc
function loginByGoogleRequest(
	data: CodeResponse,
	signal: AbortSignal,
): Promise<AxiosResponse<AuthType>> {
	return api.post('/api/Auth/LoginByGoogle', data.code, {
		signal,
		withCredentials: true,
	});
}

// TODO : Doc
function getUserAsPaid(
	userID: string,
	signal: AbortSignal,
): Promise<AxiosResponse<boolean>> {
	// TODO : bug with ", { signal }" ?
	return api.get(`/api/Auth/GetUserAsPaid/${userID}`);
}

// TODO : Doc
function disconnectRequest(): Promise<AxiosResponse<void>> {
	return api.post('/api/Auth/Disconnect', null, { withCredentials: true });
}

export { loginRequest, loginByGoogleRequest, getUserAsPaid, disconnectRequest };
