import { JSX, Suspense } from 'react';
import { Outlet } from 'react-router';
import Loader from '@components/loaders/loader';

// TODO : Doc
function AppOutlet(): JSX.Element {
	return (
		<Suspense fallback={<Loader />}>
			<Outlet />
		</Suspense>
	);
}

export default AppOutlet;
