import React, { createContext, JSX, useEffect, useState } from 'react';
import useCustomContext from '@hooks/useCustomContext.ts';
import { ChildrenProps } from '@types/commonsTypes.ts';
import {
	getLocalStorage,
	setLocalStorage,
} from '@services/storages/localStorage.ts';

// TODO : Doc
type ThemeContextProps = {
	themeMode: boolean;
	toggleThemeMode: () => void;
};

// TODO : Doc
const ThemeContext: React.Context<ThemeContextProps | null> =
	createContext<ThemeContextProps | null>(null);

// TODO : Doc
function useThemeContext(): ThemeContextProps {
	return useCustomContext(ThemeContext);
}

// TODO : Doc
function ThemeProvider({ children }: ChildrenProps): JSX.Element {
	const [themeMode, setThemeMode] = useState<boolean>(() => {
		const value = getLocalStorage<boolean>('themeMode');
		return value !== null ? value : false;
	});

	const toggleThemeMode = (): void => {
		setThemeMode((prev) => !prev);
	};

	// Update local storage
	useEffect(() => {
		setLocalStorage('themeMode', themeMode);
	}, [themeMode]);

	return (
		<ThemeContext.Provider value={{ themeMode, toggleThemeMode }}>
			{children}
		</ThemeContext.Provider>
	);
}

export type { ThemeContextProps };
export { useThemeContext };
export default ThemeProvider;
